<template>
  <div class="mt-2">
    <v-list>
      <v-list-item :to="{name: 'admin.opd.pool', params: {id: pool.id}}" :key="pool.id" v-for="pool in project_pools">
        <v-list-item-icon>
          <v-icon v-if="pool.active"
                  color="orange"
          >
            mdi-fire
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ pool.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $moment(pool.start_at).format('DD.MM') }} - {{ $moment(pool.end_at).format('DD.MM') }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn rounded color="primary" icon>
            <v-icon>
              mdi-arrow-right-circle-outline
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>

export default {
  name: "ProjectPoolsIndexComponent",
  computed: {
    project_pools() {
      return this.$store.state.dictionaries.project_pools
    }
  },
  mounted() {
    this.$store.dispatch('getProjectPools');
  }
}
</script>

<style scoped>

</style>
